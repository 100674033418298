import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  Box,
  Container,
  Divider,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { ArrowForward, KeyboardArrowDown } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

function ResponsiveAppBar(props) {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeSection,setActiveSection] = useState('Home');

  const routes = {
    Home: "/",
    About: "aboutus",
    Services: "services",
    Contact: "contact",
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderNavItem = (title) => {
    return (
      <Link
        onClick={() => {
          handleNavigation(title);
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography
          variant="body2"
          fontWeight={700}
          mr={"40px"}
          sx={{
            color: activeSection === title? "#00ADA0" : "#000000",
            // location.pathname === routes[title] ? "#00ADA0" : "#000000",
          }}
        >
          {title}
        </Typography>
      </Link>
    );
  };

  const handleGetQuote = () => {
    const googleFormLink = "https://forms.gle/CBgFZNNPoosgXdXP8";
    window.open(googleFormLink, "_blank");
  };

  const handleNavigation = (title) => {
    setActiveSection(title);
    handleMenuClose();
    if (title === "Home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (title === "About") {
      props?.scrollToSection(props?.aboutSectionRef);
    } else if (title === "Contact") {
      props?.scrollToSection(props?.contactSectionRef);
    }
  };

  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{
        // background: "linear-gradient(45deg, #DAFFFF33,#DAFFFF33)",
        backgroundColor: "#DAFFFF20",
        backdropFilter: "blur(40px)", // For the frosted glass effect
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Glossy shadow
        borderBottom: "0.5px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <Container>
        <Toolbar>
          {/* Logo */}
          {/* <IconButton edge="start" color="inherit" aria-label="logo"> */}
            <img
              src={"/images/logoNew.png"}
              alt="Logo"
              style={{
                height: isMobile ? "32px" : "80px",
                paddingTop: 8,
                paddingBottom: 8,
              }}
            />
          {/* </IconButton> */}
          {/* Space between Logo and Menu Options */}
          <div style={{ flexGrow: 1 }} />{" "}
          {/* This will push the buttons to the right */}
          {isMobile ? (
            // Mobile View: Show Menu Icon
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  sx={{
                    width: "300px",
                  }}
                  onClick={() => {
                    handleNavigation("Home");
                  }}
                >
                  {renderNavItem("Home")}
                </MenuItem>
                <Divider />

                <MenuItem
                  onClick={() => {
                    handleNavigation("About");
                  }}
                >
                  {renderNavItem("About")}
                </MenuItem>
                {/*<Divider/>

                <MenuItem onClick={handleMenuClose}>
                  {renderNavItem("Services")}
                </MenuItem>*/}
                <Divider />

                <MenuItem
                  onClick={() => {
                    handleNavigation("Contact");
                  }}
                >
                  {renderNavItem("Contact")}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleMenuClose}>
                  <Button
                    onClick={handleGetQuote}
                    sx={{
                      backgroundColor: "#00ADA0",
                      borderColor: "#00ADA0",
                      padding: "14px 32px",
                      borderRadius: "80px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      mr={"16px"}
                      color="#FFFFFF"
                    >
                      React Out
                    </Typography>
                    <ArrowForward
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "18px",
                      }}
                    />
                    {/* <Box
                      component={"img"}
                      src="/images/icons/rightArrowLong.png"
                    /> */}
                  </Button>
                </MenuItem>
              </Menu>
            </>
          ) : (
            // Desktop View: Show Buttons
            <>
              {renderNavItem("Home")}
              {renderNavItem("About")}
              {/* {renderNavItem("Services")}*/}
              {renderNavItem("Contact")}
              <Button
                onClick={handleGetQuote}
                sx={{
                  backgroundColor: "#FEFEFE",
                  borderColor: "#ECEFF3",
                  padding: "14px 32px",
                  borderRadius: "80px",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={500}
                  mr={"16px"}
                  color="#00ADA0"
                  sx={{
                    textTransform:'capitalize'
                  }}
                >
                  Reach Out
                </Typography>
                <Box component={"img"} src="/images/icons/rightArrowLong.png" />
              </Button>
              {/* <div>
                <Button
                  color="inherit"
                  onClick={handleMenuOpen} // Open the dropdown menu on click
                >
                  Services
                  <KeyboardArrowDown sx={{ marginLeft: 0.5 }} />{" "}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose} // Close the dropdown menu on close
                >
                  <MenuItem onClick={handleMenuClose}>Service 1</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Service 2</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Service 3</MenuItem>
                </Menu>
              </div> */}
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
