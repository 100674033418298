import { Box, Container, Grid2, Typography } from "@mui/material";
import React from "react";

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: "#00262E",
      }}
      pt={"56px"}
      pb={"56px"}
    >
        <Box
        // sx={{
        //     backgroundImage:"url('/images/pattern/footer.png')",
        //     backgroundSize:'cover',
        // }}
        >
      <Container>
        <Grid2 container spacing={2}>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 8,
            }}
          >
            <Box component={"img"} src="/images/logoNew.png"
            sx={{
              height:'100px',
              backgroundColor:'#FFFFFF',
              padding:'6px',
              borderRadius:'50px'
            }}
            />
            {/* <Typography
              variant="body2"
              sx={{
                position: "absolute",
                bottom: 0,
              }}
            >
              © Copyright wonderland cleaners
            </Typography> */}
          </Grid2>
          <Grid2
            size={{
              xs: 6,
              sm: 6,
              md: 2,
            }}
          >
            <Typography
              variant="body2"
              mb={"8px"}
              color="#FFFFFF"
              fontWeight={"bold"}
            >
              Quicklinks
            </Typography>
            <Typography variant="body2" mt={"12px"} color="#FFFFFF">
              Home
            </Typography>
            <Typography variant="body2" mt={"12px"} color="#FFFFFF">
              Services
            </Typography>
            <Typography variant="body2" mt={"12px"} color="#FFFFFF">
              About Us
            </Typography>
            <Typography variant="body2" mt={"12px"} color="#FFFFFF">
              Contact
            </Typography>
            <Typography variant="body2" mt={"16px"} color="#FFFFFF">
              Cookie Policy
            </Typography>
          </Grid2>
          <Grid2
            size={{
              xs: 6,
              sm: 6,
              md: 2,
            }}
          >
            <Typography
              variant="body2"
              mb={"8px"}
              color="#FFFFFF"
              fontWeight={"bold"}
            >
              Services
            </Typography>
            <Typography variant="body2" mt={"12px"} color="#FFFFFF">
              Residential
            </Typography>
            <Typography variant="body2" mt={"12px"} color="#FFFFFF">
              Commercial
            </Typography>
            <Typography variant="body2" mt={"12px"} color="#FFFFFF">
              Custom Plan
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={6}>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 8,
            }}
          >
            <Typography variant="body2" color="#FFFFFF">
              © Copyright wonderland cleaners
            </Typography>
          </Grid2>
          <Grid2
            size={{
              xs: 6,
              sm: 6,
              md: 2,
            }}
          >
            <Typography variant="body2" color="#FFFFFF">
            Cookie Policy
            </Typography>
          </Grid2>
          <Grid2
            size={{
              xs: 6,
              sm: 6,
              md: 2,
            }}
          >
            <Typography variant="body2" color="#FFFFFF">
              Privacy Policy
            </Typography>
          </Grid2>
        </Grid2>
      </Container>
      </Box>
    </Box>
  );
}
