import React, { useRef } from "react";
import { Box, Container, Typography } from "@mui/material";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import Header from "../Components/Header";
import Aboutus from "../Components/Aboutus";
import Whyus from "../Components/Whyus";
import Footer from "../Components/Footer";
import FAQ from "../Components/FAQ";
import Contact from "../Components/Contact";
import Commitment from "../Components/Commitment";
import GiveBack from "../Components/GiveBack";

export default function Home() {

  const aboutSectionRef = useRef();
  const contactSectionRef = useRef();

  const scrollToSection = (ref) => {
    const yOffset = -100; // Adjust this value to create space at the top
    const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <Box>
    <ResponsiveAppBar 
    aboutSectionRef={aboutSectionRef}
    contactSectionRef={contactSectionRef}
    scrollToSection={scrollToSection}
    />
    <Header/>
    <Aboutus
    aboutSectionRef={aboutSectionRef}
    />
    <Whyus/>
    <Commitment/>
    {/* <Box backgroundColor={'#EEF7F6'} sx={{
            paddingTop:'80px',
      paddingBottom:'80px'
    }}>
      </Box> */}
    <FAQ/>
    <Contact
    contactSectionRef={contactSectionRef}
    />
    <GiveBack/>
    <Footer/>
    </Box>
  );
}

const styles = {
  header: {},
};
