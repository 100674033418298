import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "DM Sans, sans-serif",
    h1: {
      fontSize: "64px", // Default size
      "@media (min-width:600px)": { fontSize: "72px" },
      "@media (min-width:960px)": { fontSize: "80px" },
      "@media (min-width:1280px)": { fontSize: "96px" },
      "@media (min-width:1920px)": { fontSize: "100px" },
    },
    h2: {
      fontSize: "38px", // Default size
      "@media (min-width:600px)": { fontSize: "60px" },
      "@media (min-width:960px)": { fontSize: "70px" },
      "@media (min-width:1280px)": { fontSize: "80px" },
      "@media (min-width:1920px)": { fontSize: "85px" },
    },
    h3: {
      fontSize: "32px", // Default size
      "@media (min-width:600px)": { fontSize: "50px" },
      "@media (min-width:960px)": { fontSize: "60px" },
      "@media (min-width:1280px)": { fontSize: "64px" },
      "@media (min-width:1920px)": { fontSize: "70px" },
    },
    h4: {
      fontSize: "28px", // Default size
      "@media (min-width:600px)": { fontSize: "32px" },
      "@media (min-width:960px)": { fontSize: "36px" },
      "@media (min-width:1280px)": { fontSize: "40px" },
      "@media (min-width:1920px)": { fontSize: "45px" },
    },
    h5: {
      fontSize: "20px", // Default size
      "@media (min-width:600px)": { fontSize: "24px" },
      "@media (min-width:960px)": { fontSize: "28px" },
      "@media (min-width:1280px)": { fontSize: "24px" },
      "@media (min-width:1920px)": { fontSize: "26px" },
    },
    h6: {
      fontSize: "16px", // Default size
      "@media (min-width:600px)": { fontSize: "18px" },
      "@media (min-width:960px)": { fontSize: "20px" },
      "@media (min-width:1280px)": { fontSize: "18px" },
      "@media (min-width:1920px)": { fontSize: "20px" },
    },
    body1: {
      fontSize: "14px",
      "@media (min-width:600px)": { fontSize: "16px" },
      "@media (min-width:960px)": { fontSize: "18px" },
      "@media (min-width:1280px)": { fontSize: "16px" },
      "@media (min-width:1920px)": { fontSize: "18px" },
    },
    body2: {
      fontSize: "12px",
      "@media (min-width:600px)": { fontSize: "14px" },
      "@media (min-width:960px)": { fontSize: "16px" },
      "@media (min-width:1280px)": { fontSize: "14px" },
      "@media (min-width:1920px)": { fontSize: "16px" },
    },
  },
});

export default theme;