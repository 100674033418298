import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid2,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add, Close } from "@mui/icons-material";

export default function FAQ() {
    const [expanded, setExpanded] = React.useState(false);
    const [activePanel,setActivePanel] = useState('');

    const handleChange = (panel) => (event, isExpanded) => {
        setActivePanel(panel);
      setExpanded(isExpanded ? panel : false);
    };

  const renderSingleFaq = (number, title, content) => {
    return (
      <Grid2
        container
        sx={{
          borderBottom: "1px solid #E9E9E9",
        }}
      >
        <Grid2
          size={{
            xs:2,
            sm:2,
            md:1
        }}
          sx={{
            borderRight: "1px solid #E9E9E9",
            padding:'34px 0px'
          }}
        >
          <Typography variant="h4" fontWeight={400} textAlign={"center"}>
            {number}
          </Typography>
        </Grid2>
        <Grid2 size={{
            xs:10,
            sm:10,
            md:11
        }} sx={{
            alignContent:'center'
        }}>
          <Accordion
            expanded={expanded === title}
            onChange={handleChange(title)}
            disableGutters
            sx={{
                boxShadow:'none',
                marginLeft:4
            }}
          >
            <AccordionSummary
              expandIcon={expanded&& title === activePanel?<Close/>:<Add />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography variant="h5" fontWeight={400} color="#00262E">
              {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" fontWeight={400} color="#191919">
              {content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid2>
      </Grid2>
    );
  };
  return (
    <Box pt={"80px"} pb={"80px"}>
      <Container>
        <Typography variant="h5" color="#A4ACB9">
          FAQ
        </Typography>
        <Box mt={"36px"}>
            {renderSingleFaq(1,'What types of cleaning services do you offer?','We offer a wide range of cleaning services including residential cleaning, commercial cleaning, deep cleaning, move-in/move-out cleaning, and specialized services like carpet and upholstery cleaning.')}
            {renderSingleFaq(2,'Are your cleaning products safe for pets and children?','We offer a wide range of cleaning services including residential cleaning, commercial cleaning, deep cleaning, move-in/move-out cleaning, and specialized services like carpet and upholstery cleaning.')}
            {renderSingleFaq(3,'How do I book a cleaning service?','We offer a wide range of cleaning services including residential cleaning, commercial cleaning, deep cleaning, move-in/move-out cleaning, and specialized services like carpet and upholstery cleaning.')}
            {renderSingleFaq(4,'Do I need to be home during the cleaning?','We offer a wide range of cleaning services including residential cleaning, commercial cleaning, deep cleaning, move-in/move-out cleaning, and specialized services like carpet and upholstery cleaning.')}
            {renderSingleFaq(5,"What if I'm not satisfied with the cleaning?",'We offer a wide range of cleaning services including residential cleaning, commercial cleaning, deep cleaning, move-in/move-out cleaning, and specialized services like carpet and upholstery cleaning.')}
        </Box>
      </Container>
    </Box>
  );
}
