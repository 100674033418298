import { Box, Container, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export default function Whyus() {
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderPointer = (icon, title, content) => {
    return (
      <Box
        mt={"56px"}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#EEF7F6",
            borderRadius: "50%",
          }}
        >
          <Box component={"img"} src={icon} />
        </Box>
        <Box
        ml={'24px'}
        >
          <Typography variant="h6" fontWeight={400} color="#191919">{title}</Typography>
          <Typography variant="body2" fontWeight={400} color="#191919" mt={'8px'}>{content}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        paddingTop: "80px",
        paddingBottom: "80px",
      }}
    >
      <Container>
        <Grid2 container spacing={2}>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 7,
            }}
            sx={{
              paddingRight: isMobile?0:20,
            }}
          >
            <Typography variant="h5" fontWeight={400} color="#00262E">Why Us</Typography>
            <Typography variant="h6" fontWeight={400} color="#00262E" mt={1}>
              Since 2010, Wonderland Cleaning has been dedicated to delivering
              exceptional cleaning services to Kitsap County and beyond. Here’s
              why our clients trust us:
            </Typography>
            <Box mt={"32px"}>
              {renderPointer("/images/icons/bucket.png", "Tried & True",'With 22,000+ services completed, we ensure top-quality cleaning for clients like Islandwood and Pleasant Beach Village.')}
              {renderPointer("/images/icons/call.png", "One Call Does It All",'We offer comprehensive cleaning for homes and facilities with one easy call, backed by our facilities and contracting team.')}
              {renderPointer("/images/icons/badge.png", "Honest Prices",'With Wonderland, save 2-5% on costs and hotels can cut housekeeping and laundry expenses by 5-10%. Enjoy exceptional service and increase your savings!')}
            </Box>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 5,
            }}
            sx={{
                paddingTop:isMobile?'40px':'0px'

            }}
          >
            <Box
              component={"img"}
              src="/images/whyus.png"
              sx={{
                borderRadius: "16px",
                height: "100%",
                width:'100%',
                objectFit: "cover",
              }}
            />
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}
