import { Box, Container, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export default function GiveBack() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
      }}
      pt={"80px"}
      pb={"80px"}
    >
      <Container>
        <Box
          sx={{
            width: "70%",
          }}
        >
          <Typography variant="h5" fontWeight={400} color="#00262E">
            We Love To Give Back
          </Typography>
          <Typography variant="h6" fontWeight={400} color="#191919" mt={"21px"}>
            We proudly supports our community through donations to we grow
            collective and by offering charity cleaning services to those in
            need, We’re committed to making a positive impact beyound out
            business.
          </Typography>
        </Box>
        <Box mt={"57px"}>
          <Grid2 container spacing={2}>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
            >
              <Box component={"img"} src="/images/gb1.png" sx={{
              width:'100%'
              }}/>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
            >
              <Box component={"img"} src="/images/gb2.png" sx={{
              width:'100%'
              }} />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
            >
              <Box component={"img"} src="/images/gb3.png" sx={{
              width:'100%'
              }} />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
              sx={{
                position:'relative'
              }}
            >
                {!isMobile &&
                              <Box component={"img"} src="/images/illustrations/gbIllustration.png" sx={{
                                position:'absolute',
                                top:'-160px',
                                width:'100%'
                              }} />
                }
              <Box component={"img"} src="/images/gb4.png" sx={{
              width:'100%'
              }} />
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
}
