import { Attachment } from "@mui/icons-material";
import {
  Box,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import CommonButton from "./reusable/CommonButton";

export default function Contact(props) {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#EEF7F6",
      }}
      pt={"80px"}
      pb={"80px"}
      ref={props?.contactSectionRef}
    >
      <Container>
        <Grid2 container spacing={12}>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 6,
            }}
            flexGrow={1}
          >
            <Typography variant="h5" fontWeight={400} color="#00262E">
              Contact
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Grid2 container spacing={4}>
                  <Grid2
                    size={{
                      xs: 12,
                      sm: 12,
                      md: 12,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body2"
                        fontWeight={400}
                        color="#A1A1A1"
                      >
                        Email
                      </Typography>
                      <Typography
                        variant="h5"
                        fontWeight={400}
                        fontSize={"20px"}
                        color="#191919"
                      >
                        office@wonderlandclean.com
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    size={{
                      xs: 12,
                      sm: 12,
                      md: 12,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body2"
                        fontWeight={400}
                        color="#A1A1A1"
                      >
                        Office
                      </Typography>
                      <Typography
                        variant="h5"
                        fontWeight={400}
                        fontSize={"20px"}
                        color="#191919"
                      >
                        1067 NW Carey St Poulsbo WA
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    size={{
                      xs: 12,
                      sm: 12,
                      md: 6,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body2"
                        fontWeight={400}
                        color="#A1A1A1"
                      >
                        Social
                      </Typography>
                      <Box mt={"11px"}>
                        <Box
                          component={"img"}
                          src="/images/icons/fb.png"
                          mr={"16px"}
                        />
                        <Box
                          component={"img"}
                          src="/images/icons/insta.png"
                          mr={"16px"}
                        />
                        <Box
                          component={"img"}
                          src="/images/icons/x.png"
                          mr={"16px"}
                        />
                      </Box>
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Box>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 6,
            }}
          >
            <Typography variant="h5" color="#191919" fontWeight={400}>
              Is the answer to your question missing? Get in touch with us.
            </Typography>
            <Box mt={"56px"}>
              <TextField
                label="Name"
                variant="standard"
                sx={{
                  width: "100%",
                }}
              />
              <Box mt={"40px"}>
                <Grid2 container spacing={6}>
                  <Grid2
                    size={{
                      xs: 12,
                      sm: 12,
                      md: 6,
                    }}
                  >
                    <TextField
                      label="Email"
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Grid2>
                  <Grid2
                    size={{
                      xs: 12,
                      sm: 12,
                      md: 6,
                    }}
                  >
                    <TextField
                      label="Phone"
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                    />
                  </Grid2>
                </Grid2>
              </Box>
              <Box mt={"40px"}>
                <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Age
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mt={"40px"}>
                <TextField
                  label="Message"
                  multiline
                  rows={2}
                  variant="standard"
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                mt={"36px"}
              >
                <Attachment
                  sx={{
                    transform: "rotate(45deg)",
                  }}
                />
                <Typography variant="14" fontWeight={500} ml={"8px"}>
                  Attach
                </Typography>
              </Box>
              <CommonButton
                title={"Submit"}
                style={{
                  marginTop: "60px",
                }}
              />
            </Box>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}
