import { Button, Typography } from '@mui/material'
import React from 'react'

export default function CommonButton(props) {
  return (
    <Button
    sx={{
      padding:'14px 50px',
      backgroundColor:'#00ADA0',
      borderRadius:'80px',
      ...props?.style
    }}
    >
      <Typography variant='body2' fontWeight={500} color='#FFFFFF'>
{props?.title}
</Typography>
    </Button>
  )
}
