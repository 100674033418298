import { Box, Typography } from '@mui/material'
import React from 'react'

export default function Header() {
  return (
    <Box
    sx={{
      height: "100vh",
      width: "100%",
      overflowX: "hidden",
      backgroundImage: `
      linear-gradient(to bottom, transparent 50%, #00ADA0 100%), 
      url('/images/header.png')
    `,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}
  >
    {/* <Container> */}
    <Box
      sx={{
        background:
          "linear-gradient(2.91deg, rgba(255, 255, 255, 0.4) 32.29%, rgba(153, 153, 153, 0) 70.43%)",
        height: 450,
        position:'absolute',
        bottom:0,
        width:'100%',
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          background:
            "radial-gradient(ellipse at 50% 50%,rgba(255, 255, 255, 0.6) 32.29%, rgba(153, 153, 153, 0) 70.43%)",
            height:400,
        alignContent: "center",
        }}
      >
        <Typography variant="h2" fontWeight={900} textAlign="center">
        Cleaning Kitsap,
        </Typography>
        <Typography variant="h3" textAlign="center" fontWeight={"300"}>
          One Space at a Time!
        </Typography>
        <Typography
          variant="h6"
          align="center"
          mt={"32px"}
          fontWeight={400}
        >
          From offices to homes , our expert team ensures a spotless
          environment,<br/>leaving you with more time to enjoy life.
        </Typography>
      </Box>
    </Box>
    {/* </Container> */}
  </Box>
  )
}
