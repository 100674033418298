import { Box, Container, Grid2, Typography } from '@mui/material'
import React from 'react'

export default function Aboutus(props) {
  return (
    <Box
    sx={{
        backgroundColor:'#EEF7F6',
        paddingTop:'80px',
        paddingBottom:'80px'
    }}
    ref={props?.aboutSectionRef}
    >
        <Container>
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs:12,sm:12,md:2 }}>
                    <Typography variant='h5' fontWeight={400} color='#00262E'>
                        About Us
                    </Typography>
                </Grid2>
                <Grid2 size={10}>
                    <Typography variant='h6' fontWeight={400} color='#191919'>
                    At Wonderland Cleaning, we've been committed to making Kitsap County shine for over 13 years. Trusted by leading names like Islandwood, Pleasant Beach Village, and Silverdale Center for Surgery, our reputation for reliability, affordability, and professionalism speaks for itself. As a proud local business, we go beyond just cleaning—we give back, contributing a portion of our revenue to support local charities. Our commitment to our community is matched only by our dedication to our clients.
                    </Typography>
                </Grid2>
            </Grid2>
            <Box
            component={'img'}
            src='/images/aboutUsVideo.png'
            sx={{
                borderRadius:'16px',
                width:'100%'
            }}
            mt={'48px'}
            />
            </Container>
    </Box>
  )
}
